import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layouts';

const NotFoundPage: React.SFC = () => (
  <Layout title="Page Not Found">
    <Container>
      <Row>
        <Col xs={12}>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
